@font-face {
	font-family: "Nunito";
	src: url("../assets/fonts/nunito.eot");
	src: url("../assets/fonts/nunito.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/nunito.woff2") format("woff2"),
		url("../assets/fonts/nunito.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "Nunito-Semibold";
	src: url("../assets/fonts/nunito_semibold.eot");
	src: url("../assets/fonts/nunito_semibold.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/nunito_semibold.woff2") format("woff2"),
		url("../assets/fonts/nunito_semibold.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "Nunito-Bold";
	src: url("../assets/fonts/nunito_bold.eot");
	src: url("../assets/fonts/nunito_bold.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/nunito_bold.woff2") format("woff2"),
		url("../assets/fonts/nunito_bold.woff") format("woff");
	font-display: swap;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	outline: none;
}

ul {
	list-style: none;
}

body {
	font-family: "Nunito";
	font-weight: normal;
	background-color: #f0f0f0;
}
body::-webkit-scrollbar {
	display: none;
}
a {
	font-family: "Nunito";
	text-decoration: none;
	outline: none;
	/* -webkit-appearance: none; */
	-webkit-tap-highlight-color: transparent;
}

.wc a {
	color: #fff;
}

.wc a:hover {
	color: #ffd801;
}

strong {
	font-family: "Nunito-Bold";
	font-weight: normal;
}

img {
	display: block;
	max-width: 100%;
	max-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

h3 {
	font-family: "Nunito-Bold";
	font-size: 2.31vw;
	letter-spacing: 0.095vw;
	line-height: 1.1;
	text-transform: uppercase;
}

.cap {
	text-transform: capitalize;
}

h4 {
	font-family: "Nunito";
	line-height: 1.1;
	font-size: 1.85vw;
	color: #ffd801;
	padding-top: 0.53vw;
}

h6 {
	font-family: "Nunito-Bold";
	font-size: 1.25vw;
	line-height: 1.1;
	text-transform: uppercase;
}

.lsp {
	letter-spacing: 0.09vw;
}

.up {
	text-transform: uppercase;
}

.container {
	position: relative;
	width: 100%;
	padding: 0 5vw;
	box-sizing: border-box;
}

p {
	font-size: 1.11vw;
	color: #000;
	line-height: 1.62vw;
	margin-top: 1vw;
}

p:first-child {
	margin-top: 0;
}

.mt0 {
	margin-top: 0;
}

.ff-sb {
	font-family: "Nunito-Semibold";
}

.small {
	font-size: 0.93vw;
	line-height: 1.48vw;
	margin-top: 1.16vw;
}

.lh4 {
	line-height: 1.02vw;
}

.fs2 {
	font-size: 1.16vw;
	line-height: 1.47;
}

.fs3 {
	font-size: 3.24vw;
	line-height: 1;
}

.fs4 {
	font-size: 1.3vw;
	line-height: 1.1;
}

.fs5 {
	font-size: 1.48vw;
	line-height: 1;
}

.fs6 {
	font-size: 2.31vw;
	line-height: 1;
}

sup {
	font-size: 2vw;
}

.sub {
	font-size: 1.39vw;
	line-height: 1.1;
	margin-top: 0;
}

.white,
.wc p,
.wc h1,
.wc h3,
.wc h4,
.wc h5 {
	color: #fff;
}

main {
	padding-top: 1.48vw;
}

header {
	padding-bottom: 1.85vw;
}

header,
footer {
	background-color: #002d56;
}

.logo {
	height: 3.8vw;
	margin-top: 1.39vw;
}

.fl,
.fs {
	display: flex;
	flex-wrap: wrap;
}

.fs {
	align-items: flex-start;
}

.va,
.cent {
	display: flex;
	flex-wrap: wrap;
	align-it3ms: center;
}

.cent,
.jc {
	justify-content: center;
}

.tc {
	text-align: center;
}

.tr {
	text-align: right;
}
/*header*/
.header-title {
	background-color: #d24a3b;
	border-bottom-left-radius: 1.5vw;
	border-bottom-right-radius: 1.5vw;
	/* padding: 3.61vw 6.3vw; */

	padding: 2.61vw 6.3vw;
}

.header-block {
	border-bottom: 0.05vw solid rgba(255, 255, 255, 0.7);
	padding: 1.1vw 0 1.34vw 0;
}

.fl11 {
	flex: 1 1;
}

.icon {
	height: 4.64vw;
	margin-right: 0.69vw;
}

.block-cont {
	min-height: 4.64vw;
}
/*header end*/

.opt,
.block,
.image {
	background-size: cover;
	background-position: center;
}

/*video begin*/
.video,
.block {
	border-radius: 1.39vw;
}

.video {
	position: relative;
	height: 33.7vw;
	overflow: hidden;
}

.video h3 {
	position: absolute;
	top: 3.24vw;
	left: 3.24vw;
}

.video-icon {
	position: absolute;
	height: 4.17vw;
	top: 1.85vw;
	right: 1.85vw;
}
/*video end*/

/*locations begin*/
.block {
	background-color: #fff;
	padding: 1.39vw;
	margin-top: 1.62vw;
}

.patterns {
	background-size: 170% auto;
	/* padding-top: 3.15vw; */
}

.blue {
	background-color: #002d56;
}

.location {
	/* background-color: #d24a3b; */
	background-color: #002d56;
	border-radius: 0.93vw;
	margin-top: 1.3vw;
	overflow: hidden;
}

.location:first-child {
	margin-top: 0;
}

.loc-icon {
	width: 7.87vw;
	padding: 0.69vw;
}
.loc-icon-sm {
	width: 6.4vw;
	padding: 0.69vw;
}

.loc-icon img {
	height: 5.56vw;
}

.loc-block {
	display: flex;
	align-items: flex-end;
	background-color: #002d56;
	border-radius: 0.93vw;
	padding: 0.73vw 0.69vw;
}
.loc-block-c {
	display: flex;
	align-items: center;
	background-color: #002d56;
	border-radius: 0.93vw;
	padding: 0.73vw 0.69vw;
}
.loc-headerIndiv {
	color: white;
	display: flex;
	justify-content: center;
}
.loc-header {
	display: flex;
	border-radius: 0.46vw;
	min-height: 2vh;
	color: white;
	background-color: #002d56;
	/* justify-content: space-around; */
	align-items: center;
}

.loc-desc {
	width: 100%;
	min-height: 3.15vw;
	display: flex;
	align-items: center;
	background-color: #ffd801;
	border-radius: 0.46vw;
	padding: 0.93vw;
	margin-top: 0.5vw;
}
.loc-header-col {
	display: flex;
	justify-content: center;
	width: 25%;
}
.lhcl {
	padding-left: 12.5%;
}
.lhclm {
	padding-left: 9.5%;
}
.lhcrm {
	padding-left: 5%;
}
.lhclr {
	padding-left: 1%;
}
.loc-desc p {
	font-size: 1.11vw;
	line-height: 1.2;
}

.loc-right {
	width: 15.74vw;
	margin-left: 0.5vw;
}

.time {
	height: 1.39vw;
	margin-right: 0.5vw;
}
/*locations end*/

.image {
	height: 31.53vw;
	border-radius: 0.7vw;
}

.hsmall .image {
	height: 17.39vw;
}

.item .image {
	border-radius: 0;
}

.logos {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 2.3vw;
}

.logo1 {
	height: 3.84vw;
}

.logo2 {
	height: 6.85vw;
}

/*items begin*/
.things {
	/* padding-bottom: 1.25vw; */
	/* padding-bottom: 0.5vw; */
	font-size: 1.5vw;
}

/* .thing-desc { */
/*   font-size: 1.5vw; */
/* } */
.w3 {
	width: 32.333%;
	margin-right: 1.5%;
	margin-top: 1.39vw;
}

.item {
	background-color: #fff;
	border-radius: 1.39vw;
	overflow: hidden;
}

.w3:nth-child(3n) {
	margin-right: 0;
}

.item-title {
	min-height: 7.94vw;
	min-height: 13.94vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #002d56;
	padding-left: 1.5%;
	padding-right: 1.5%;
}

.item-title img {
	max-height: 5.47vw;
}

.mtm .items {
	margin-top: -15.33vw;
}

.mtm {
	margin-top: 17vw;
}

.mt {
	margin-top: 3.84vw;
}

.item-desc {
	padding: 1.6vw;
	padding-bottom: 3vw;
}

.pt0 {
	padding-top: 0;
}

.number {
	height: 9.79vw;
	background-color: #ffd801;
	border-radius: 0.5vw;
}

.number p {
	font-family: "Nunito-Bold";
	font-size: 4.7vw;
	line-height: 1;
}

.bottom {
	border-top: 0.05vw solid rgba(0, 0, 0, 0.4);
	padding-top: 1vw;
	margin-top: 1.1vw;
}

.line {
	border-bottom: 0.05vw solid rgba(0, 0, 0, 0.4);
	padding: 1.42vw 0 1.14vw 0;
}

.min {
	line-height: 1.2;
	text-transform: uppercase;
	padding-left: 0.8vw;
}

.line p {
	margin-top: 0;
}

.info {
	padding: 2.45vw 0;
}

.info-text {
	border-left: 1px solid rgba(255, 255, 255, 0.8);
	padding-left: 1.6vw;
	margin-left: 1.6vw;
}

.info-text p {
	font-size: 1.2vw;
	line-height: 2vw;
}

.info-text .name {
	font-size: 1.48vw;
}

.company-desc {
	margin-top: 0.3vw;
}
